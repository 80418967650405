import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: '',
            text: 'Project Settings',
            link: '#',
            items: [
                {
                    text: 'UI Theme settings',
                    link: '/styles-theme/'
                },
                {
                    text: 'Global Registration',
                    link: '/global-registration/'
                },
                {
                    text: 'UI Layouts',
                    link: '/ui-layouts/'
                },
                {
                    text: 'Lego Commands',
                    link: '/lego-commands/'
                }
            ]
        },
        {
            icon: '',
            text: 'Helpers',
            link: '#',
            items: [
                {
                    text: 'Grid System',
                    link: '/grid-system-docs/'
                },
                {
                    text: 'Color Helpers',
                    link: '/color-helpers/'
                },
                {
                    text: 'BreadCrumb',
                    link: '/app/master-data/'
                },
                {
                    text: 'Paginations',
                    link: '/app/master-data/'
                },
                {
                    text: 'Progress Steps',
                    link: '/app/master-data/'
                },
                {
                    text: 'Crud items',
                    link: '/app/master-data/'
                }
            ]
        },
        {
            text: 'Directives',
            link: '#',
            items: [
                {
                    text: 'Click Outside',
                    link: '/click-outside-directive/'
                }
            ]
        },
        {
            text: 'Design System',
            link: '#',
            items: [
                {
                    text: 'Color System',
                    link: '/color-system-docs/'
                },
                {
                    text: 'Typography',
                    link: '/typography-docs/'
                }
            ]
        }

    ]
    ;

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
