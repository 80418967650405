import store from '../store';

const getSideMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: '',
            text: 'Helpers',
            link: '/dashboard/',
            items: [
                {
                    text: 'Color Helpers',
                    link: '/color-helpers/'
                },
                {
                    text: 'BreadCrumb',
                    link: '/app/master-data/'
                },
                {
                    text: 'Paginations',
                    link: '/app/master-data/'
                },
                {
                    text: 'Progress Steps',
                    link: '/app/master-data/'
                },
                {
                    text: 'Crud items',
                    link: '/app/master-data/'
                }
            ]
        }

    ];

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getSideMenu;
