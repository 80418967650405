import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: '',
            text: 'Core Components',
            link: '#',
            items: [
                {
                    text: 'Button',
                    link: '/button-docs/'
                },
                {
                    text: 'Input',
                    link: '/input-docs/'
                },
                {
                    text: 'Mask Input',
                    link: '/mask-input-docs/'
                },
                {
                    text: 'Checkbox',
                    link: '/checkbox-docs/'
                },
                {
                    text: 'Date Picker',
                    link: '/date-picker-docs/'
                },
                {
                    text: 'File Input',
                    link: '/file-input-docs/'
                },
                {
                    text: 'File Input Customized',
                    link: '/file-input-customized-docs/'
                },
                {
                    text: 'Select',
                    link: '/select-docs/'
                },
                {
                    text: 'Ajax Select',
                    link: '/ajax-select-docs/'
                },
                {
                    text: 'Textarea',
                    link: '/textarea-docs/'
                }
            ]

        },
        {
            icon: '',
            text: 'Dashboard Components',
            link: '#',
            items: [
                {
                    text: 'Drag and Drop File Input',
                    link: '/file-upload/'
                },
                {
                    text: 'BreadCrumb',
                    link: '/base-breadcrumb/'
                },
                {
                    text: 'Paginations',
                    link: '/paginations/'
                },
                {
                    text: 'Progress Steps',
                    link: '/progress-steps-docs/'
                },
                {
                    text: 'Crud items',
                    link: '/crud-items/'
                },
                {
                    text: 'Metrics',
                    link: '/metrics/'
                },
                {
                    text: 'Metrics Type 2',
                    link: '/metrics-two/'
                },
                {
                    text: 'Activity Gauge',
                    link: '/activity-gauge/'
                },
                {
                    text: 'Activity Customizable',
                    link: '/activity-gauge-customisable/'
                },
                {
                    text: 'Listing Cards',
                    link: '/listing-card/'
                }
            ]
        },
        {
            text: 'Web Components',
            link: '#',
            items: [
                {
                    text: 'Team Avatar',
                    link: '/team-avatar-variants/'
                },
                {
                    text: 'Feature Section',
                    link: '/features-section-item.json-variants/'
                },
                {
                    text: 'Testimonials',
                    link: '/Testimonials-Variants/'
                },
                {
                    text: 'Metrics',
                    link: '/Matrics-Variants-Page/'
                },
                {
                    text: 'Blog Item Card',
                    link: '/blog-item-card/'
                },
                {
                    text: 'Faq Item',
                    link: '/faq/'
                },
                {
                    text: 'Pricing Card',
                    link: '/price-cards/'
                },
                {
                    text: 'Newsletter',
                    link: '/Newsletter-Variants-Page/'
                },
                {
                    text: 'Base Footer',
                    link: '/base-footer/'
                },
                {
                    text: 'Faq Accordion',
                    link: '/faq-accordion/'
                },
                {
                    text: 'Contact Us',
                    link: '/contact-us/'
                },
                {
                    text: 'Progress Steps',
                    link: '/progress-steps-docs/'
                },
                // {
                //     text: 'Notification Modal',
                //     link: '/notification-modal/'
                // },
                // {
                //     text: 'Alert Modal',
                //     link: '/alert-modal/'
                // },
                {
                    text: 'Progress Circle',
                    link: '/progress-circle/'
                },
                {
                    text: 'Team Section Plain',
                    link: '/team-section-plain/'
                },
                {
                    text: 'Team Section Colored',
                    link: '/team-section-colored/'
                },
                {
                    text: 'Banner Section',
                    link: '/banner-section/'
                },
                {
                    text: 'Side Bar',
                    link: '/side-bar/'
                },
                {
                    text: 'Top Navbar',
                    link: '/top-nav/'
                },
                {
                    text: 'Block Quote',
                    link: '/block-quote/'
                },
                {
                    text: 'Social Icons',
                    link: '/social-icons/'
                }
            ]
        },
        {
            icon: '',
            text: 'Modules',
            link: '#',
            items: [
                {
                    text: 'Login Pages',
                    link: '/login-pages/'
                }
            ]
        },
        {
            icon: '',
            text: 'Project Settings',
            link: '#',
            items: [
                {
                    text: 'UI Theme settings',
                    link: '/styles-theme/'
                },
                {
                    text: 'Global Registration',
                    link: '/global-registration/'
                },
                {
                    text: 'UI Layouts',
                    link: '/ui-layouts/'
                },
                {
                    text: 'Lego Commands',
                    link: '/lego-commands/'
                }
            ]
        },
        {
            icon: '',
            text: 'Containers',
            link: '/about/',
            items: [
                {
                    text: 'Modal',
                    link: '/modal/'
                },
                {
                    text: 'Container',
                    link: '/container/'
                },
                {
                    text: 'Card',
                    link: '/card/'
                },
                {
                    text: 'Flex',
                    link: '/flex/'
                },
                {
                    text: 'Grid Row',
                    link: '/grid-row/'
                },
                {
                    text: 'Grid Column',
                    link: '/grid-col/'
                },
                {
                    text: 'Tabs',
                    link: '/tab/'
                },
                {
                    text: 'Lego Section',
                    link: '/section/'
                },
                {
                    text: 'Custom Size Container',
                    link: '/size-container/'
                },
                {
                    text: 'Custom Spacing Container',
                    link: '/spacing-container/'
                }
            ]
        },
        {
            icon: '',
            text: 'Css',
            link: '#',
            items: [
                {
                    text: 'Color',
                    link: '/color-helpers/'
                },
                {
                    text: 'Border',
                    link: '/border-helpers/'
                },
                {
                    text: 'Flex Alignments',
                    link: '/helpers-flex-alignment/'
                },
                {
                    text: 'Positions',
                    link: '/helpers-position-helpers/'
                },
                {
                    text: 'Display',
                    link: '/helpers-display-helpers/'
                },
                {
                    text: 'Shadow',
                    link: '/helpers-shadows-helpers/'
                },
                {
                    text: 'Width',
                    link: '/helpers-width-helpers/'
                },
                {
                    text: 'Spaces',
                    link: '/helpers-spaces/'
                },
                {
                    text: 'Z Index',
                    link: '/helpers-z-index/'
                },
                {
                    text: 'Transforms',
                    link: '/helpers-transforms/'
                },
                {
                    text: 'Entry Exit Animation',
                    link: '/helpers-entry-exit-animation/'
                },
                {
                    text: 'Image and Background',
                    link: '/helpers-image-and-background/'
                },
                {
                    text: 'Form Modifiers',
                    link: '/helpers-form-modifiers/'
                }
            ]
        },
        {
            icon: '',
            text: 'Scss',
            link: '#',
            items: [
                {
                    text: 'Spacers',
                    link: '/spacers/'
                },
                {
                    text: 'Size Variations',
                    link: '/size-variations/'
                },
                {
                    text: 'Border Radius',
                    link: '/border-radius/'
                },
                {
                    text: 'Base variables',
                    link: '/base-variables/'
                },
                {
                    text: 'Font Weight',
                    link: '/font-weight/'
                },
                {
                    text: 'Gray Colors',
                    link: '/gray-colors/'
                },
                {
                    text: 'Animation',
                    link: '/animation/'
                },
                {
                    text: 'Dropper',
                    link: '/dropper/'
                },
                {
                    text: 'Headings and Others',
                    link: '/headings-and-others/'
                },
                {
                    text: 'Theme Colors',
                    link: '/theme-colors/'
                },
                {
                    text: 'Bg and Text',
                    link: '/bg-text/'
                },
                {
                    text: 'Table Variables',
                    link: '/table-variables/'
                },
                {
                    text: 'Heights',
                    link: '/heights/'
                },
                {
                    text: 'Buttons',
                    link: '/buttons/'
                },
                {
                    text: 'Color Variations',
                    link: '/color-variations/'
                },
                {
                    text: 'Grid',
                    link: '/grid/'
                },
                {
                    text: 'Custom Spacing Elements',
                    link: '/custom-spacing-element/'
                },
                {
                    text: 'Card',
                    link: '/cards/'
                },
                {
                    text: 'Modals',
                    link: '/modals/'
                },
                {
                    text: 'Tabs',
                    link: '/tabs/'
                },
                {
                    text: 'Side Navbar Plain',
                    link: '/side-nav-plain/'
                },
                {
                    text: 'Warning',
                    link: '/warning/'
                },
                {
                    text: 'Top Navbar',
                    link: '/top-navbar/'
                },
                {
                    text: 'Activity Gauge',
                    link: '/activity-gauges/'
                },
                {
                    text: 'Fonts & Typography',
                    link: '/fonts-typography/'
                },
                {
                    text: 'Font Items',
                    link: '/font-items/'
                },
                {
                    text: 'Custom Height Container',
                    link: '/custom-height-container/'
                },
                {
                    text: 'Side Navbar',
                    link: '/side-navbar/'
                },
                {
                    text: 'Page Layouts',
                    link: '/page-layouts/'
                },
                {
                    text: 'Scrollbars',
                    link: '/scrollbars/'
                },
                {
                    text: 'Badge',
                    link: '/badge/'
                },
                {
                    text: 'Typography',
                    link: '/typography/'
                },
                {
                    text: 'Pagination',
                    link: '/pagination/'
                },
                {
                    text: 'Blog Item Card',
                    link: '/blog-item-cards/'
                },
                {
                    text: 'Form Control floating',
                    link: '/form-control-floating/'
                },
                {
                    text: 'Avatar Image',
                    link: '/avatar-img/'
                },
                {
                    text: 'Tag Item',
                    link: '/tag-items/'
                },
                {
                    text: 'Forms',
                    link: '/forms/'
                },
                {
                    text: 'success',
                    link: '/success/'
                },
                {
                    text: 'Progress Steps',
                    link: '/progress-step/'
                },
                {
                    text: 'Danger and Error',
                    link: '/danger-error/'
                },
                {
                    text: 'Size Variation',
                    link: '/variation/'
                },
                {
                    text: 'Font Weight and Line Height base Values',
                    link: '/fontweight-lineheight/'
                }
            ]
        },
        {
            icon: '',
            text: 'UI Elements',
            link: '#',
            items: [
                {
                    text: 'Tag Item Plain',
                    link: '/tag-item-plain/'
                },
                {
                    text: 'Tag with Two Texts',
                    link: '/Tag-Item-two-text/'
                },
                {
                    text: 'Tag Item',
                    link: '/Tag-Item/'
                },
                {
                    text: 'Icon Text',
                    link: '/icon/'
                },
                {
                    text: 'Card',
                    link: '/card/'
                },
                {
                    text: 'Icon Image',
                    link: '/icon-image/'
                },
                {
                    text: 'Text Element',
                    link: '/text-element/'
                }
            ]
        },
        {
            icon: '',
            text: 'Tables',
            link: '#',
            items: [
                {
                    text: 'Simple Table',
                    link: '/simple-table/'
                },
                {
                    text: 'Vue Table',
                    link: '/vue-table/'
                },
                {
                    text: 'Vue Table Card',
                    link: '/vue-table-card/'
                }
            ]
        }

    ]
        ;

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true
    ) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
}
;
export default getMenu;
