<template>
    <div class="" id="top">
<!--        <div class="bg-2 w-100p pos-ft z-index-max">-->
<!--            <div class="fl-x fl-j-c w-100p bg-2" style="position: fixed; top:0; z-index: 999;">-->
<!--                <lego-nav-bar @link-event="linkEvent" dropdown-round="0" ul-classes="" li-classes="py-1" child-menu-additional-classes="" menus-horizontal-alignment="right"-->
<!--                              :has-separator="false" hover-effect="basic-1" drop-down-animation="fade" drop-down-animation-mobile="fade" :colored="false" :items="menu" shadow="0"-->
<!--                              class="w-100p container" logo-text="">-->
<!--                    <template #logo>-->
<!--                        <router-link class="fl-x-cc" to="/">-->
<!--                            <img src="../assets/images/logo/xeo-lego.png" class="mr-2" alt="">-->
<!--                            <span class="text-primary">Lego Docs</span>-->
<!--                        </router-link>-->
<!--                    </template>-->
<!--                </lego-nav-bar>-->
<!--            </div>-->
<!--        </div>-->
        <div class="page page-sidebar">
            <lego-side-bar class="w-20r h-100vh bg-2 pos-r" @link-event="linkEvent" drop-down-animation="fade" :items="menu"
                           :logo-text="''"
                           :colored="false" has-separator
                           @logo-clicked="redirect">
                <template #logo>
                    <router-link class="fl-x-cc" to="/">
                        <img src="../assets/images/logo/xeo-lego.png" class="mr-2" alt="">
                        <span class="text-primary">Lego Docs</span>
                    </router-link>
                </template>
            </lego-side-bar>

            <div class="p-4 w-100p">
                <transition name="fade" mode="out-in">
                    <router-view>

                    </router-view>
                </transition>
            </div>
        </div>

        <!--        <base-footer></base-footer>-->
        <div class="bg-dark p-3">
            Powered by Xeoscript Technologies
        </div>
    </div>

</template>

<script>
// import LegoNavBar from 'lego-framework/src/components/NavBar';
import LegoSideBar from 'lego-framework/src/components/SideNavBar';
import getMenu from '../data/menu';
import getSideMenu from '../data/side-menu';
import getMenu1 from '../data/sidemenu';
// import BaseFooter from '../components/BaseFooter';

export default {
    components: {
        // BaseFooter,
        LegoSideBar
    },
    data () {
        return {
            menu: getMenu(),
            sideMenu: getSideMenu(),
            menu1: getMenu1(),
            value: ''
        };
    },
    methods: {
        linkEvent (name) {
            if (name === 'test') {
                alert('test');
            }
        }
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
</style>
